<template lang="pug">
#downloads
    b-container.download-wrap(fluid)
        b-row.pt-1.pb-1
            //- b-col(cols="12" md="3")
            //-     h3.text-primary.light-font Certificate
            //- b-col(cols="12" md="9")
            //-     b-row
            //-         b-col.p-1(cols="12" md="auto")
            //-             span.square
            //-             a.download-link(href="https://orantech.com/wp-content/uploads/2021/02/ISO13485-Q5-17-11-98084-004_EN_04052768526144.pdf" download target="_blank") TUV ISO Certificate

        //- b-row.border-top.pt-1.pb-1
        //-     b-col(cols="12" md="3")
        //-         h3.text-primary.light-font Compliance
        //-     b-col(cols="12" md="9")
        //-         b-row
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2022/03/DOC-001-06Declaration-of-Conformity-Patient-CableTEMP-Adapter-Cables.pdf" download target="_blank") Declaration of Conformity Patient Cable(TEMP Adapter Cables)
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2022/03/DOC-001-05Declaration-of-Conformity-Patient-Cable-（Air-hose.pdf" download target="_blank") Declaration of Conformity Patient Cable （Air hose)
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2022/03/DOC-001-04Declaration-of-Conformity-SPO2-Adapter-Cables.pdf" download target="_blank") Declaration of Conformity SPO2 Adapter Cables
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2022/03/DOC-001-03-Declaration-of-Conformity-IBP-Interface-Cables.pdf" download target="_blank") Declaration of Conformity IBP Interface Cables
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-006-01-Declaration-of-Conformity-Ultrasound-Fetal-transducer-1.pdf" download target="_blank") Declaration of Conformity Ultrasound Fetal transducer
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-006-02-Declaration-of-Conformity-TOCO-Fetal-transducer.pdf" download target="_blank") Declaration of Conformity TOCO Fetal transducer
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-003-01-Declaration-of-Conformity-TEMP-Probe.pdf" download target="_blank") Declaration of Conformity TEMP Probe
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-002-01-Declaration-of-Conformity-SpO2-Sensor.pdf" download target="_blank") Declaration of Conformity SpO2 Sensors
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-001-01-Declaration-of-Conformity-ECG-leadwires.pdf" download target="_blank") Declaration of Conformity Patient cable and leadwires
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-005-01-Declaration-of-Conformity-NIBP-Cuff.pdf" download target="_blank") Declaration of Conformity NIBP Cuff
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-007-01-Declaration-of-Conformity-ETCO2-Sensor.pdf" download target="_blank") Declaration of Conformity ETCO2 Sensor
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/DOC-001-02-Declaration-of-Conformity-DECG-Legplate-Adapter-Cables.pdf" download target="_blank") Declaration of Conformity DECG Legplate Adapter Cables
        
        //- b-row.border-top.pt-1.pb-1
        //-     b-col(cols="12" md="3")
        //-         h3.text-primary.light-font User Manual
        //-     b-col(cols="12" md="9")
        //-         b-row
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Temperature_Adapter_Cables.pdf" download target="_blank") Temperature Adapter Cables User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_SpO2_Adapter_Cables.pdf" download target="_blank") SpO2 Adapter Cables User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Reusable_Temperature_Probes.pdf" download target="_blank") Reusable Temperature Probes User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/04/M20_M006_Reusable_SpO2_Sensors_QR-1.pdf" download target="_blank") Reusable SpO2 Sensors User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Reusable_NIBP_Cuffs.pdf" download target="_blank") Reusable NIBP Cuffs User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_NIBP_Air_Hoses.pdf" download target="_blank") NIBP Air Hoses User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_IBP_Adapter_Cables.pdf" download target="_blank") IBP Adapter Cables User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Fetal_Ultrasound_Transducers.pdf" download target="_blank") Fetal Ultrasound Transducers User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Fetal_TOCO_Transducers.pdf" download target="_blank") Fetal TOCO Transducers User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_EtCO2_Sensors.pdf" download target="_blank") EtCO2 Sensors User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_EKG_Patient_Cables.pdf" download target="_blank") EKG Patient Cables User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Turbo_Temperature_Probes.pdf" download target="_blank") Turbo Temperature Probes User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_ECG_Cables_Leadwires.pdf" download target="_blank") ECG Cables and Leadwires User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2021/03/User_Manual_Disposable_NIBP_Cuffs.pdf" download target="_blank") Disposable NIBP Cuffs User Manual
        //-             b-col.p-1(cols="12" md="6")
        //-                 span.square
        //-                 a.download-link(href="https://orantech.com/wp-content/uploads/2020/10/M20_M008_Single_patient_use_SpO2_Sensors_QR.pdf" download target="_blank") Disposable SpO2 Sensors User Manual
        
        b-row.border-top.pt-1.pb-1
            b-col(cols="12" md="3")
                h3.text-primary.light-font {{ $t('search.label.warranty') }}
            b-col(cols="12" md="9")
                b-row
                    b-col.p-1(cols="12" md="auto")
                        span.square
                        a.download-link(:href="warrantyLink" target="_blank") {{ $t('search.label.medten_warranty_terms')}}
        
        b-row.border-top.pt-1.pb-1
            b-col(cols="12" md="3")
                h3.text-primary.light-font {{ $t('search.label.brochures') }}
            b-col(cols="12" md="9")    
                b-row
                    b-col.p-1(cols="12" md="auto")
                        span.square
                        a.download-link(:href="boucherLink"  target="_blank") {{ $t('search.label.full_category_brochure')}}
                    
    
    MissionPromiseStatement
    Footer
</template>

<script>
import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
import Footer from '@/components/Footer.vue';
export default {
    components: {
        Footer,
        MissionPromiseStatement
    },
    computed: {
        warrantyLink() {
            if (this.$i18n.locale === 'en') {
                return "static/docs/English_Warranty.pdf"
            } else {
                return "static/docs/Chinese_Translated_Warranty.pdf"
            }
        },
        boucherLink(){
            if (this.$i18n.locale === 'en') {
                return "static/docs/OT_brochure_EN.pdf"
            } else {
                return "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/OT_brochure_ZH.pdf"
            }
        }
    },
}
</script>

<style lang="scss" scoped>
#downloads{
    .download-wrap{
        margin: 20px 70px;
        @media(max-width: 767px){
            margin: 20px 15px;
        }
    }
    .light-font {
        font-weight: 300;
    }
    .download-link {
        text-decoration: none;
        font-size: 1rem;
        color: #353535;
    }
    .square {
        float: left;
        height: 10px;
        width: 10px;
        margin: 8px;
        border: 1px solid #68C3C5;
        clear: both;
        background-color: #68C3C5;
    }
}
</style>