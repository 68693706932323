<template lang="pug">
#general
    .content-wrap.light-font
        .main-title.text-primary
            h2.light-font {{ $t('general.gen_heading') }}</h1>

        b-row.content-block
            b-col.d-md-none(cols="12" md="4")
                h3.light-font {{ $t('general.gen_acc') }}
            b-col(cols="12" md="4")
                b-img.box-img(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_compatible_accessories_for_GE_Phillips_Masimo_Nellcor_Mindray_Nihon-Koden_Datex.jpg" alt="")
            b-col(cols="12" md="4")
                h3.light-font.d-none.d-md-block {{ $t('general.gen_acc') }}
                p.mt-2 {{ $t('general.acc_para') }}
                a.link( href="/accessories/")
                    span.mt-blue.pr-1 
                        b-img.bullet(src="@/assets/images/bullet.svg") 
                    | {{ $t('general.read_more') }}
            b-col.d-none.d-md-block(cols="12" md="4")
                b-img.box-img.d-none.d-md-block(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Accessories_Wireframe.gif" alt="")
        
        b-row.content-block
            b-col.d-md-none(cols="12" md="4")
                h3.light-font {{ $t('general.gen_parts') }}
            b-col(cols="12" md="4")
                b-img.box-img(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Orantech_medical_equipment_replacement_parts_fetal_telemetry_monitor_modules_infusion_pumps.jpg" alt="")
            b-col(cols="12" md="4")
                h3.light-font.d-none.d-md-block {{ $t('general.gen_parts') }}
                p.mt-2 {{ $t('general.parts_para') }}
                a.link(href="/parts/")
                    span.mt-blue.pr-1 
                        b-img.bullet(src="@/assets/images/bullet.svg") 
                    | {{ $t('general.read_more_parts') }}
            b-col.d-none.d-md-block(cols="12" md="4")
                b-img.box-img(src="https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N_Parts_Wireframe.gif" alt="")

        .quality-block
            .quality-title.mt-blue
                h3.light-font {{ $t('general.quality_policy') }}
            p {{$t('general.quality_heading')}}
        
        ul.quality-list
            li {{ $t('general.policy_l1') }}
            li {{ $t('general.policy_l2') }}
            li {{ $t('general.policy_l3') }}
            //- li Orantech's unique QR code labeling system now allows our customers to track every cable from manufacturing to consumer and beyond. Access to a complete product history, specifications, sales and support is now at your fingertips.
            li {{ $t('general.policy_l4') }}
            li {{ $t('general.policy_l5') }}
        //- .featured-title-wrap.mt-blue
        //-     h2.featured-title.light-font FEATURED PRODUCTS
        //- .product-item-wrap(v-for="(product, i) in productData" :key="i")
        //-     AboutProductItem(
        //-     :title="product.title"
        //-     :dateInfo="product.dateInfo"
        //-     :brands="product.brands"
        //-     :models="product.models"
        //-     :features="product.features"
        //-     :imageUrls="product.imageUrls"
        //-     :popularProducts="product.popularProducts"
        //-     :downloadUrls="product.downloadUrls")
        br
    MissionPromiseStatement
    Footer
</template>

<script>
    import AboutProductItem from '@/components/AboutProductItem.vue';
    import MissionPromiseStatement from '@/components/MissionPromiseStatement.vue';
    import Footer from '@/components/Footer.vue';
    export default {
        name: 'General',
        components: {
            AboutProductItem,
            MissionPromiseStatement,
            Footer
        },
        data: () => ({
            productData: [
                {
                    title: "Leg Plate used with Corometrics Fetal Spiral Electrodes",
                    dateInfo: {
                        date: "November 1, 2021",
                        group: "Accessories"
                    },
                    brands: ["GE Healthcare", "Philips Avalon", "Huntleigh"],
                    features: [
                        "Engineered to functionally equivalent to OEMs",
                        "Securely connected and used with Corometrics fetal spiral electrodes",
                        "Compatible with major brands fetal monitoring system",
                        "Affordable prices",
                        "CE certificated and FDA clearance",
                        "12 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_GE01_SGE.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_GE02_SGE_2.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Fetal-FDELA_PH01_SGE.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "FDELA-GE01-SGE",
                            description: "2.5m, used with Corometrics Spiral Electrodes",
                            brands: "GE Corometrics",
                            references: "1591AA0",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FDELA-GE02-SGE",
                            description: "2.5m, used with Corometrics Spiral Electrodes",
                            brands: "GE Corometrics",
                            references: "1590AA0",
                            package: "1 pc/bag"
                        },
                        {
                            number: "FDELA-PH01-SGE",
                            description: "2.5m, used with Corometrics Spiral Electrodes",
                            brands: "Philips Avalon",
                            references: "M1349A",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Infusion Pump Parts for BD/Carefusion/Alaris",
                    dateInfo: {
                        date: "October 15, 2020",
                        group: "Parts"
                    },
                    brands: ["BD/Carefusion"],
                    models: ["Alaris 8015", "Alaris 8000", "Alaris 8000", "Alaris 8120"],
                    features: [
                        "Brand new replacements",
                        "Appearance and functionality are equivalent to OEMs",
                        "Quality is guaranteed",
                        "Up to 50% savings",
                        "6 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N_Infusion_Pump_Parts_for_BD_Carefusion_Alaris.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/07/Parts-for-infusion-pump.pdf",
                            message: "Infusion Pump Parts Brochure"
                        }
                    ]
                },
                {
                    title: "Mindray Telemetry Parts",
                    dateInfo: {
                        date: "November 3, 2021",
                        group: "Parts"
                    },
                    brands: ["Mindray"],
                    models: ["Panorama Telepack 608"],
                    features: [
                        "Brand new replacements",
                        "Appearance and functionality are equivalent to OEMs",
                        "Quality is guaranteed",
                        "Up to 50% savings",
                        "6 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_02.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_04.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_05.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_06.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028_07.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-tele-mindray-KLN_028-1.jpg"
                    ],
                    popularProducts: [
                        {
                            number: "KLN-028",
                            description: "Tele Assembly kit",
                            brands: "Mindray",
                            references: "",
                            package: "1 set/box"
                        },
                        {
                            number: "KLN-028-05",
                            description: "Case Shell",
                            brands: "Mindray",
                            references: "",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLN-028-07",
                            description: "Front Panel Overlay with Keypad",
                            brands: "Mindray",
                            references: "",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLN-028-04",
                            description: "Bottom housing",
                            brands: "Mindray",
                            references: "0380-00-0490",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLN-028-01",
                            description: "ECG Block",
                            brands: "Mindray",
                            references: "DA0153",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLN-028-02",
                            description: "ECG Rubber Cover",
                            brands: "Mindray",
                            references: "0380-00-0491-01",
                            package: "1 pc/bag"
                        },
                        {
                            number: "KLN-028-03",
                            description: "Bottom Rubber Cover",
                            brands: "Mindray",
                            references: "0380-00-0477",
                            package: "1 pc/bag"
                        }
                    ],
                },
                {
                    title: "Fetal Transducer Parts for Philips Avalon",
                    dateInfo: {
                        date: "October 15, 2020",
                        group: "Parts"
                    },
                    brands: ["Philips/Avalon"],
                    models: ["M2734B", "M2735A", "M2736A"],
                    features: [
                        "Brand new replacements",
                        "Appearance and functionality are equivalent to OEMs",
                        "Quality is guaranteed",
                        "Up to 50% savings",
                        "6 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_F007.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_F008.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/KGF_M007.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N_Fetal_Transducer_Parts_for_Philips_Avalon.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/07/Parts-for-fetal-transducers.pdf",
                            message: "Fetal Transducer Parts Brochure"
                        }
                    ]
                },
                {
                    title: "Monitor and Modules for Philips",
                    dateInfo: {
                        date: "October 17, 2020",
                        group: "Parts"
                    },
                    brands: ["Philips"],
                    models: ["MP series", "X2/MP2/M3002A", "M3001A", "M3012A", "M3014A", "M3015A", "M3016A", "M1116B"],
                    features: [
                        "Brand new replacements",
                        "Appearance and functionality are equivalent to OEMs",
                        "Quality is guaranteed",
                        "Up to 50% savings",
                        "6 months warranty"
                    ],
                    imageUrls: [
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-monitor-Philips-KLN_009_03.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_019.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_029.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_040_01.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/Parts-Monitor-Philips-KLN_040_06.jpg",
                        "https://s3.us-west-1.amazonaws.com/images.medten.com/images/site/N_Monitor_and_Modules_for_Philips.jpg"
                    ],
                    downloadUrls: [
                        {
                            url: "https://orantech.com/wp-content/uploads/2021/07/Parts-for-monitor-module.pdf",
                            message: "Monitor / Module Parts Brochure"
                        }
                    ]
                },

            ]
        })
    }
</script>

<style lang="scss" scoped>
    .main-title {
        margin: 10px 10px 40px;
    }
    .mt-blue {
        color: #68C3C5;
        img{
            padding-bottom: 3px;
        }
    }
    .light-font {
        font-weight: 400;
    }
    .link {
        color: black;
        text-decoration: none;
        font-weight: 500;
        font-size: 14px;
        &:hover{
            text-decoration: underline;
        }
    }
    .content-wrap {
        padding: 0px 70px;
        @media( max-width: 767px){
            padding: 0px 15px;
        }
    }
    .box-img {
        border: 3px solid #68C3C5;
        border-radius: 40px;
        border-top-left-radius: 0px !important;
        max-width: 100%;
    }
    .content-block {
        display: flex;
        margin: 40px 0;
    }
    .text-block {
        margin: 0 5%;
    }
    .quality-list {
        font-weight: 400;
        font-size: 1rem;
    }
    .quality-block {
        margin-left: 1rem;
    }
    .featured-title {
        text-align: center;
    }
    .product-item-wrap {
        margin: 0 -70px;
        padding: 5% 90px;
        border-bottom: 3px solid #68C3C5;
        @media(max-width: 767px){
            margin: 0;
            padding: 0;
        }
    }
</style>