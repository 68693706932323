<template lang="pug">
#faq
  b-container.mt-4.mb-4 
    |
    h4.text-primary.mb-3 {{ $t("faq.faq_text") }}
    .faq-list-wrap 
      |
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-1) {{ $t("faq.q1") }}
          b-collapse#faq-1(visible, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans1") }}
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-2) {{ $t("faq.q2") }}
          b-collapse#faq-2(close, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans2") }}
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-3) {{ $t("faq.q3") }}
          b-collapse#faq-3(close, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans3") }}
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-4) {{ $t("faq.q4") }}
          b-collapse#faq-4(close, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans4") }}
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-5) {{ $t("faq.q5") }}
          b-collapse#faq-5(close, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans5") }}
      .accordion(role="tablist")
        b-card.mb-1(no-body)
          b-card-header(header-tag="header", role="tab")
            .accordion-title(block, v-b-toggle.faq-6) {{ $t("faq.q6") }}
          b-collapse#faq-6(close, accordion="my-faq", role="tabpanel")
            b-card-body
              p {{ $t("faq.ans6") }}
      br 
      p {{ $t("sourcing.blue_link_prefix") }}
        |
        a(href="#", @click="openModal('footer-inquiry-modal')")
          strong {{ $t("product.sourcing.filling_form") }}
        |
        | {{ $t("product.sourcing.need_s") }}
  MissionPromiseStatement
  Footer
</template>

<script>
import MissionPromiseStatement from "@/components/MissionPromiseStatement.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    MissionPromiseStatement,
    Footer,
  },
  methods: {
    openModal(id) {
      this.$bvModal.show(id);
    },
  },
};
</script>

<style lang="scss" scoped>
#faq {
  .faq-list-wrap {
    .card-header {
      &:hover {
        background: #38bcd9;
        color: white;
      }
      .accordion-title {
        font-size: 1rem;
        font-weight: 500;
      }
    }
    .card-body {
      p {
        font-size: 1rem;
      }
    }
  }
}
</style>